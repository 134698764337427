export default [
  {
    key: "file_name",
    label: "Name",
    class: "text-left",
    visible: true,
  },
  {
    key: "url",
    label: "URL",
    tdClass: "text-center",
    thClass: "text-center",
    visible: true,
  },
];
