<template>
  <b-modal
    v-model="onControl"
    title="Inquiry Solution"
    title-class="font-weight-bolder text-white"
    modal-class="modal-primary"
    @hidden="close"
    size="md"
    scrollable
    centered
    hide-footer
  >
    <div
      class="mb-2 d-flex justify-content-between"
      style="gap: 15px"
      v-if="defaultView"
    >
      <div class="w-100">
        <span class="text-primary font-weight-bolder mb-1 h4 d-block">
          INQUIRY
        </span>
        <b-textarea v-model="inquiryText" :disabled="true" size="lg" />
      </div>
      <div class="w-100">
        <span class="text-primary font-weight-bolder mb-1 h4 d-block">
          SOLUTION
        </span>
        <b-textarea v-model="solutionText" :disabled="true" size="lg" />
      </div>
    </div>
    <span
      class="text-primary font-weight-bolder mb-1 h4 d-block"
      v-if="defaultView"
    >
      FILES
    </span>
    <b-table
      id="inquiry-files-list"
      ref="inquiry-files-table"
      no-border-collapse
      class="position-relative"
      :fields="fields"
      show-empty
      no-provider-filtering
      sticky-header="50vh"
      primary-key="id"
      responsive="sm"
      :items="items"
      :busy="isBusy"
      :current-page="currentPage"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading ...</strong>
        </div>
      </template>

      <template #cell(url)="{ item }">
        <a :href="item.url" target="_blank" class="ml-1">
          <amg-icon
            v-if="item.extension === 'docx' || item.extension === 'doc'"
            class="cursor-pointer text-primary"
            icon="FileWordIcon"
            size="18"
          />
          <amg-icon
            v-else-if="item.extension === 'pdf'"
            class="cursor-pointer text-danger"
            icon="FilePdfIcon"
            size="18"
          />
          <amg-icon
            v-else-if="item.extension === 'xlsx' || item.extension === 'xls'"
            class="cursor-pointer text-success"
            icon="FileExcelIcon"
            size="18"
          />
          <amg-icon
            v-else-if="item.extension === 'pptx' || item.extension === 'ppt'"
            class="cursor-pointer text-warning"
            icon="FilePowerpointIcon"
            size="18"
          />
          <feather-icon
            v-else-if="item.extension === 'jpg' || item.extension === 'png'"
            class="cursor-pointer text-info"
            icon="ImageIcon"
            size="18"
          />
          <feather-icon
            v-else-if="item.extension === 'zip' || item.extension === 'rar'"
            class="cursor-pointer text-info"
            icon="BriefcaseIcon"
            size="18"
          />
          <feather-icon
            v-else
            class="cursor-pointer text-info"
            icon="FileIcon"
            size="18"
          />
        </a>
      </template>
    </b-table>
  </b-modal>
</template>

<script>
import InquiryClaimService from "../services/inquiry-claim.service";
import dataFields from "@/views/commons/components/claims_v2/views/claim-inquiries/data/fields.inquiry-files.js";

export default {
  props: {
    inquiryId: {
      type: Number,
      required: true,
    },
    solutionText: {
      type: String,
      required: true,
    },
    inquiryText: {
      type: String,
      required: true,
    },
    defaultView: {
      type: Boolean,
      requireD: true,
    },
  },
  data() {
    return {
      onControl: false,
      items: [],
      fields: dataFields,
      isBusy: false,
      currentPage: 1,
    };
  },

  async created() {
    this.addPreloader();
    await this.start();
    this.removePreloader();
  },

  methods: {
    async start() {
      await this.myProvider();
      this.onControl = true;
    },
    async myProvider() {
      const data = await InquiryClaimService.getInquiryFiles({
        inquiry_id: this.inquiryId,
      });

      this.items = data;
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
