<template>
  <div class="px-2">
    <filter-slot
      :filter="filterSlot.filters"
      :total-rows="filterSlot.totalRows"
      :paginate="filterSlot.paginate"
      :start-page="filterSlot.startPage"
      :to-page="filterSlot.toPage"
      :filter-principal="filterSlot.filterPrincipal"
      @reload="$refs['inquiryClaimsTable'].refresh()"
      @reset-all-filters="resetAllFilters"
    >
      <b-table
        slot="table"
        no-provider-filtering
        ref="inquiryClaimsTable"
        :items="myProvider"
        :fields="myFields"
        primary-key="id"
        table-class="text-nowrap"
        responsive="sm"
        show-empty
        :busy.sync="isBusy"
        :per-page="filterSlot.paginate.perPage"
        :current-page="filterSlot.paginate.currentPage"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>
        <template #cell(client)="data">
          <span class="d-flex align-items-center" style="gap: 5px">
            <span v-if="moduleId == 28 && data.item.module !== 28">
              <h6 style="color: #0aafdb">
                {{ data.item.client }}
              </h6>
            </span>
            <router-link
              v-else-if="moduleId == 28 && data.item.module == 28"
              target="_blank"
              :to="{
                name: 'specialists-client-dashboard',
                params: {
                  idClient: `${data.item.account_id}`,
                },
              }"
            >
              {{ data.item.client }}
            </router-link>

            <router-link
              v-else
              target="_blank"
              :to="{
                name: 'quality-client-dashboard',
                params: {
                  idClient: `${data.item.account_id}`,
                },
              }"
            >
              {{ data.item.client }}
            </router-link>
            <b-badge v-if="data.item.on_hold" variant="light-warning"
              >HOLD</b-badge
            >
          </span>
          <status-account :account="data.item" :text="true"/>
          <div>
            <small class="d-block">
              {{ data.item.account }}
            </small>
            <small v-if="data.item.mobile" class="d-block">
              {{ data.item.mobile }}
            </small>
          </div>
        </template>
        <template #cell(inquiry)="{ item }">
          <p>
            {{ item.inquiry | limitChars(45) }}
            <span
              v-if="item.inquiry.length > 45"
              class="cursor-pointer text-primary"
              @click="openFullTextModal(1, item)"
            >
              see more
            </span>
          </p>
        </template>
        <template #cell(solution)="{ item }">
          <feather-icon
            icon="InfoIcon"
            size="20"
            class="text-info cursor-pointer m-1"
            v-b-tooltip.hover.top="`View solution`"
            @click="openInquiryFilesModal(item)"
          />
        </template>
        <template #cell(priority)="{ item }">
          <b-badge :variant="variantPriority(item.priority)">
            {{ item.priority }}
          </b-badge>
        </template>
        <template #cell(created_at)="data">
          <span class="d-block">{{ data.item.created_by }}</span>
          <span class="d-block">{{ data.item.created_at | myGlobalDay }} </span>
        </template>
        <template #cell(updated_at)="data">
          <span class="d-block">{{ data.item.solved_by }}</span>
          <span class="d-block">{{ data.item.solved_at | myGlobalDay }} </span>
        </template>

        <template v-slot:cell(views)="{ item }">
          <feather-icon
            v-if="item.status_view === 1"
            class="text-success m-1"
            size="20"
            v-b-tooltip.hover.left="'Viewed'"
            icon="CheckIcon"
          />

          <feather-icon
            v-else
            size="20"
            class="text-warning parpadea m-1"
            icon="CheckIcon"
          />
        </template>
        <template #cell(actions)="data">
          <feather-icon
            icon="EyeIcon"
            size="20"
            class="text-info cursor-pointer m-1"
            v-b-tooltip.hover.top="`View claim`"
            @click="openModalDetails(data.item)"
          />
          <feather-icon
            v-if="status === 'PENDING' && moduleId == 28"
            icon="MessageSquareIcon"
            size="16"
            class="align-middle text-primary cursor-pointer"
            v-b-tooltip.hover.top="`Answer inquiry`"
            @click="inquiryModal(data.item)"
          />
        </template>
      </b-table>
    </filter-slot>
    <inquiry-modal
      v-if="showInquiryModal"
      :inquiry="selectedInquiry"
      @closeModal="closeModal"
      @refresh="refreshTable"
    />
    <ModalDetail
      :selectedClaim="selectedItemClaim"
      v-if="showModalDetail"
      @close="closeModalDetail"
    />
    <inquiry-files-modal
      v-if="showInquiryFilesModal"
      :inquiryId="selectedInquiry.inquiry_id"
      :inquiryText="selectedInquiry.inquiry"
      :solutionText="selectedInquiry.solution"
      :defaultView="true"
      @close="closeInquiryFilesModal"
    />

    <b-modal
      v-model="showFullTextModal"
      :title="typeText == 1 ? 'INQUIRY' : 'SOLUTION'"
      @hidden="closeFullTextModal"
      centered
      hide-footer
      size="sm"
    >
      {{ fullText }}
    </b-modal>
  </div>
</template>
<script>
import SolvedInquiryModal from "./SolveInquiryModal.vue";
import dataFields from "../data/fields.table-inquiries";
import InquiryClaimService from "../services/inquiry-claim.service";
import ModalDetail from "@/views/commons/components/claims_v2/components/Modals/ModalDetail.vue";
import InquiryFilesModal from "@/views/commons/components/claims_v2/views/claim-inquiries/views/InquiryFilesModal.vue";
import dataFilters from "@/views/commons/components/claims_v2/data/inquiries.filters.js";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "InquiryClaim",
  props: {
    status: String,
  },
  components: {
    "inquiry-modal": SolvedInquiryModal,
    ModalDetail,
    StatusAccount,
    InquiryFilesModal,
  },
  data() {
    return {
      dataFields,
      filterSlot: {
        filters: dataFilters,
        totalRows: 0,
        paginate: {
          currentPage: 1,
          perPage: 10,
        },
        startPage: null,
        toPage: null,
        nextPage: null,
        filterPrincipal: {
          type: "input",
          inputType: "text",
          placeholder: "Cient...",
          model: "",
        },
      },
      isBusy: false,
      showInquiryModal: false,
      inquiryId: null,
      clientAccount: null,
      clientAccountId: null,
      selectedItemClaim: null,
      showModalDetail: false,
      showInquiryFilesModal: false,
      selectedInquiry: null,
      showFullTextModal: false,
      fullText: "",
      typeText: null,
    };
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    myFields: function () {
      this.dataFields[4].visible = this.status == "SOLVED";
      this.dataFields[5].visible = this.status == "SOLVED";
      this.dataFields[6].visible =
        this.status == "SOLVED" && this.moduleId == 18;
      return this.dataFields.filter((field) => field.visible);
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  created() {
    this.filterSlot.filters[3].visible = this.status == "SOLVED";
  },
  methods: {
    ...mapActions({
      A_PENDING_INQUIRIES: "ClaimsStore/A_PENDING_INQUIRIES",
    }),
    async myProvider() {
      this.isBusy = true;
      let dataset;
      const parameters = {
        status: this.status,
        search: this.filterSlot.filterPrincipal.model,
        page: this.filterSlot.paginate.currentPage,
        perPage: this.filterSlot.paginate.perPage,
        from: this.filterSlot.filters[0].model,
        to: this.filterSlot.filters[1].model,
        createdBy: this.filterSlot.filters[2].model,
        answeredBy: this.filterSlot.filters[3].model,
        user_id: this.currentUser.user_id,
      };
      try {
        dataset = await InquiryClaimService.getInquiryClaims(parameters);
        this.filterSlot.startPage = dataset.data.from;
        this.filterSlot.paginate.currentPage = dataset.data.current_page;
        this.filterSlot.perPage = dataset.data.per_page;
        this.filterSlot.totalRows = dataset.data.total;

        this.filterSlot.endPage = dataset.data.last_page;
        this.filterSlot.totalRows = dataset.data.total;
        this.filterSlot.toPage = dataset.data.to;

        this.isBusy = false;
      } catch (error) {
        console.log(error);
      }
      const { data } = dataset.data;
      return data;
    },
    closeModal() {
      this.showInquiryModal = false;
    },
    inquiryModal(data) {
      this.selectedInquiry = data;
      this.showInquiryModal = true;
    },
    refreshTable() {
      this.$refs["inquiryClaimsTable"].refresh();
    },
    async openModalDetails(item) {
      try {
        this.addPreloader();
        const { data } = await InquiryClaimService.getClaimForIdToInquiry(
          item.claim_id
        );
        const { selectedItemClaim } = data;
        this.selectedItemClaim = selectedItemClaim;
        this.removePreloader();
        this.showModalDetail = true;
      } catch (ex) {
        console.log(ex);
      }
    },
    closeModalDetail() {
      this.selectedItemClaim = null;
      this.showModalDetail = false;
    },
    async openInquiryFilesModal(item) {
      this.selectedInquiry = item;
      if (item.status_view !== 1) {
        await this.changeView(item);
      }
      this.showInquiryFilesModal = true;
      item.status_view = 1;
    },
    async closeInquiryFilesModal() {
      await this.A_PENDING_INQUIRIES();
      this.showInquiryFilesModal = false;
    },

    async changeView(item) {
      const params = { id: item.inquiry_id, user_id: this.currentUser.user_id };
      await InquiryClaimService.changeInquiryStatusView(params);
    },

    variantPriority(value) {
      let colorPriority = "secondary";
      switch (value) {
        case "HIGH": {
          colorPriority = "light-danger";
          break;
        }
        case "HALF": {
          colorPriority = "light-warning";
          break;
        }
        case "LOW": {
          colorPriority = "light-success";
          break;
        }
      }
      return colorPriority;
    },
    openFullTextModal(type, item) {
      this.showFullTextModal = true;
      this.fullText = type == 1 ? item.inquiry : item.solution;
      this.typeText = type;
    },
    closeFullTextModal() {
      this.showFullTextModal = false;
      this.typeText = null;
    },
    resetAllFilters() {
      this.filterSlot.filters.forEach((filter) => {
        filter.model = null;
      });
      this.filterSlot.filterPrincipal.model = null;
      this.refreshTable();
    },
  },
};
</script>

<style scoped>
.parpadea {
  animation-name: parpadeo;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  -webkit-animation-name: parpadeo;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}

@-moz-keyframes parpadeo {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes parpadeo {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes parpadeo {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
