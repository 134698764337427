<template>
  <div>
    <b-modal
      v-model="showModal"
      modal
      title="Register answer"
      size="md"
      modal-class="modal-primary"
      title-tag="h3"
      :no-close-on-backdrop="true"
      @hidden="closeModal"
    >
      <validation-observer ref="form">
        <validation-provider v-slot="{ errors, valid }" rules="required">
          <span class="text-primary font-weight-bolder h4 d-block">
            Answer
          </span>
          <b-textarea
            v-model="answer"
            class="d-flex justify-content-between w-100"
            :state="errors[0] ? false : valid ? true : null"
            autofocus
          />
          <b-form-invalid-feedback>
            Answer {{ errors[0] }}
          </b-form-invalid-feedback>
        </validation-provider>
      </validation-observer>
      <span class="text-primary font-weight-bolder h4 mt-1 d-block">
        Upload file(s):
      </span>
      <drag-and-drop v-model="files" :files-array="files" />

      <template #modal-footer>
        <b-button variant="danger" @click="closeModal()">Cancel</b-button>
        <b-button variant="primary" @click="sendResponse()">Send</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import DragAndDrop from "@/views/commons/utilities/DragAndDrop";
import InquiryClaimService from "../services/inquiry-claim.service";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      showModal: false,
      viewImage: "/assets/images/icons/sms-img.png",
      answer: "",
      files: [],
    };
  },
  props: {
    inquiry: {
      type: Object,
      required: true,
    },
  },
  components: {
    DragAndDrop,
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  created() {
    this.showModal = true;
  },
  methods: {
    ...mapActions({
      A_PENDING_INQUIRIES: "ClaimsStore/A_PENDING_INQUIRIES",
    }),
    closeModal() {
      this.$emit("closeModal");
    },
    async sendResponse() {
      const result = await this.$refs.form.validate();
      if (result) {
        const confirm = await this.showConfirmSwal();
        if (!confirm.value) {
          return;
        }

        this.addPreloader();
        let formData = new FormData();
        this.files.map((file) => {
          formData.append("files[]", file, file.name);
        });
        formData.append("answer", this.answer);
        formData.append("inquiryId", this.inquiry.inquiry_id);
        formData.append("moduleId", this.currentUser.modul_id);
        formData.append("client_account", this.inquiry.account);
        formData.append("client_account_id", this.inquiry.account_id);
        formData.append("userId", this.currentUser.user_id);
        formData.append("claimLevel", this.inquiry.level_id);
        try {
          let data = await InquiryClaimService.saveResponseInquiry(formData);
          if (data.success) {
            this.showToast(
              "success",
              "top-right",
              "Success!",
              "CheckIcon",
              "Successful operation"
            );
            this.A_PENDING_INQUIRIES();
            this.$emit("refresh");
            this.closeModal();
          } else {
            this.showToast(
              "danger",
              "top-right",
              "Failed!",
              "XIcon",
              "Failed operation"
            );
          }
        } catch (error) {
          this.showErrorSwal(error);
        }
      }
      this.removePreloader();
    },
  },
};
</script>
