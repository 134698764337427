export default [
    {
        key: "client",
        label: "Client",
        class: "text-left",
        thStyle: "width: 15%",
        visible: true,
    },
    {
        key: "inquiry",
        label: "Inquiry",
        class: "text-left",
        thStyle: "width: 25%",
        visible: true,
    },
    {
        key: "priority",
        label: "Claim priority",
        class: "text-center",
        visible: true,
    },
    {
        key: "created_at",
        label: "Created by",
        class: "text-center",
        visible: true,
    },
    {
        key: "updated_at",
        label: "Answered by",
        class: "text-center",
        visible: true,
    },
    {
        key: "solution",
        label: "solution",
        class: "text-center",
        visible: true,
    },
    {
        key: "views",
        label: "Views",
        class: "text-center",
        visible: true,
    },
    {
        key: "actions",
        label: "Actions",
        class: "text-center",
        visible: true,
    },
]