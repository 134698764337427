import inquiryClaimService from "../views/claim-inquiries/services/inquiry-claim.service";

let dataFilters = [
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "From",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    cols: 6,
  },
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "To",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    cols: 6,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Created by",
    model: null,
    options: [],
    reduce: "id",
    selectText: "label",
    cols: 12,
    department: "Quality",
    visible: true,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Answered by",
    model: null,
    options: [],
    reduce: "id",
    selectText: "label",
    cols: 12,
    department: "Specialists",
    visible: false,
  },
];

dataFilters.forEach(async (item) => {
  if (item.department) {
    const { quality_users, specialists } =
      await inquiryClaimService.getUsersForInquiries();
    switch (item.department) {
      case "Quality":
        item.options = quality_users;
        break;

      case "Specialists":
        item.options = specialists;
    }
  }
});
export default dataFilters;
